import {Vue, Component} from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class MomentDateMixin extends Vue {
  formatTime(date: string) {
    return moment(date).format('HH:mm');
  }

  createdAt(time: string) {
    const now = moment();
    const then = moment(time);

    if (then.isSame(now, 'day')) {
      return moment(time).format('HH:mm');
    }
    if (
      then.isBetween(
        moment()
          .startOf('day')
          .subtract(6, 'days'),
        now,
      )
    ) {
      return moment(time).format('ddd');
    }
    return moment(time).format('MMM DD, YYYY');
  }
}
