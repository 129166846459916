






























import {Component, Mixins, Vue} from 'vue-property-decorator';
import ChatMessages from '@/components/ChatMessages.vue';
import lineClamp from 'vue-line-clamp';
import {RoomInterface} from '@/types/RoomInterface';
import {vxm} from '@/store';
import {AxiosResponse} from 'axios';
import ChatMenu from '@/components/ChatMenu.vue';
import clickOutside from '@/utils/clickOutsideElement';
import {MessageInterface} from '@/types/MessageInterface';
import Loader from '@/components/Loader.vue';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import moment from 'moment';

Vue.use(lineClamp);

@Component({
  components: {ChatMenu, ChatMessages, Loader},
  directives: {
    clickOutside,
  },
})
export default class Chat extends Mixins(MomentDateMixin) {
  chats: RoomInterface[] = [];
  currentChat: RoomInterface | null = null;
  isMenuShow = false;
  menuIndex: number | null = null;
  params = {
    page: 1,
    limit: 20,
  };
  isRequestSend = false;
  isMobile = false;
  totalChats = 0;

  get sortedChats(): RoomInterface[] {
    return this.chats.sort((a, b) => {
      if (a.message) {
        if (!b.message || moment(a.message.createdAt).isAfter(b.message.createdAt)) {
          return -1;
        }
        return 1;
      }
      if (b.message) {
        return 1;
      }
      return 0;
    });
  }
  get userId() {
    return vxm.user.data._id;
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    this.getChats().then(() => {
      if (this.$route.query.chatId) {
        for (let i = 0; i < this.chats.length; i++) {
          if (this.chats[i]._id === this.$route.query.chatId) {
            this.currentChat = this.chats[i];
            break;
          }

          if (i === this.chats.length - 1) {
            this.addNewChat(this.$route.query.chatId as string).then(() => {
              if (this.chats.length) {
                this.currentChat = this.chats[0];
              }
            });
          }
        }
      }
    });

    this.sockets.subscribe('message', (res: {data: MessageInterface; event: string}) => {
      for (let i = 0; i < this.chats.length; i++) {
        if (this.chats[i]._id === res.data.roomId) {
          this.updateChat(this.chats[i], res.data);
          this.moveContactToTop(i);
          break;
        }

        if (i === this.chats.length - 1) {
          this.addNewChat(res.data.roomId);
        }
      }
    });
  }

  destroyed() {
    this.sockets.unsubscribe('message');
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.onResize);
  }

  getChats() {
    return new Promise<{items: RoomInterface[]; total: number}>((resolve) => {
      this.isRequestSend = true;
      vxm.user
        .getRooms(this.params)
        .then((res: AxiosResponse<{items: RoomInterface[]; total: number}>) => {
          this.chats.push(...res.data.items);
          this.totalChats = res.data.total;
          this.params.page++;
          resolve(res.data);
        })
        .finally(() => {
          this.isRequestSend = false;
        });
    });
  }

  addNewChat(id: string) {
    return new Promise((resolve) => {
      vxm.user.getRoom(id).then((res: AxiosResponse<RoomInterface>) => {
        this.chats.unshift(res.data);
        resolve(res.data);
      });
    });
  }

  moveContactToTop(index: number) {
    if (index) {
      this.chats.unshift(...this.chats.splice(index, 1));
    }
  }

  handleScroll() {
    const bottomOfWindow = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (bottomOfWindow && !this.isRequestSend && this.totalChats > this.chats.length) {
      this.getChats();
    }
  }

  updateChat(chat: RoomInterface, message: MessageInterface) {
    chat.message = message;
    if (chat._id !== this.currentChat?._id) {
      chat.unread++;
    }
  }

  deleteChat(chat: RoomInterface, index: number) {
    vxm.user.deleteRoom(chat._id).then(() => {
      this.chats.splice(index, 1);
    });
  }

  getPreviewMessage(message: MessageInterface): string {
    if (message?.message) {
      return message.message;
    }

    if (message?.medias && message?.medias.length) {
      return 'Shared Media';
    }

    return 'No messages yet';
  }

  showMenu(index: number) {
    this.isMenuShow = true;
    this.menuIndex = index;
  }

  closeMenu() {
    this.isMenuShow = false;
  }

  onResize() {
    this.isMobile = window.innerWidth <= 750;
  }
}
