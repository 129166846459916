




























































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {vxm} from '@/store';
import {AxiosResponse} from 'axios';
import moment from 'moment';
import {VEmojiPicker} from 'v-emoji-picker';
import linkify from 'vue-linkify';
import ChatMenu from '@/components/ChatMenu.vue';
import LoaderBottom from '@/components/LoaderBottom.vue';
import imageQuantityUploadLimit from '@/constants/imageQuantityUploadLimit';
import imageTypes from '@/constants/imageTypes';
import heicValidationRegex from '@/constants/heicValidationRegex';
import {imageSizeLimitInBytes, imageSizeLimitInMB} from '@/constants/imageSizeLimit';
import {ApiMediaTypes} from '@/constants/apiMediaTypes';
import {videoDurationLimitFormatted, videoDurationLimitSeconds} from '@/constants/videoDurationLimit';
import {videoSizeLimitBytes, videoSizeLimitMB} from '@/constants/videoSizeLimit';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import {GetMessagesParamsInterface} from '@/types/GetMessagesParamsInterface';
import {MessageInterface, MessageMediaInterface} from '@/types/MessageInterface';
import {RoomInterface} from '@/types/RoomInterface';
import clickOutside from '@/utils/clickOutsideElement';
import debounce from '@/utils/debounce';
import CoolLightBox from 'vue-cool-lightbox';

@Component({
  components: {VEmojiPicker, ChatMenu, LoaderBottom, CoolLightBox},
  directives: {
    clickOutside,
    linkify,
  },
})
export default class ChatMessages extends Mixins(MomentDateMixin) {
  message = '';
  media: File[] = [];
  imageThumbnails: string[] = [];
  messages: {data: MessageInterface[]; total: number} = {data: [], total: 0};
  messagesContainer = {} as HTMLElement;
  showEmoji = false;
  isMenuShow = false;
  menuId: string | null = null;
  params: GetMessagesParamsInterface = {
    page: 1,
    limit: 20,
  };
  mediaErrorList = {
    ImageFormatError: 'Wrong image format. Only jpeg, png and gif formats are allowed.',
    ImageSizeError: `Image size should be less than ${imageSizeLimitInMB}MB.`,
    ImageQuantityError: `Please choose up to ${imageQuantityUploadLimit} media in total.`,
    ImageFileError: 'Image file error. Check the file or choose another one.',
    VideoFormatError: 'Wrong video format. Only mp4 format is allowed.',
    VideoDurationError: `Video should be less than ${videoDurationLimitFormatted} long.`,
    VideoSizeError: `Video size should be less than ${videoSizeLimitMB}MB.`,
    VideoFileError: 'Video file error. Check the file or choose another video.',
    FileUploadError: 'Error happened while uploading a file. Check your file or try again later.',
  };
  viewerItems = [] as {src: string; thumb?: string; autoplay?: boolean}[];
  viewerIndex = null as null | number;

  debounceHandler = () => {
    /* Will be replaced */
  };

  @Prop() chat!: RoomInterface;

  mounted() {
    this.messagesContainer = document.getElementById('messages-container') as HTMLElement;
    this.initLoad();

    this.sockets.subscribe('message', (res: {data: MessageInterface; event: string}) => {
      if (res.data.roomId === this.chat._id) {
        this.messages.data.unshift(res.data);
        this.messages.total++;
        this.readMessage(res.data);
      }
    });

    this.sockets.subscribe('message:read', (res: {messages?: string[]; roomId: string}) => {
      if (this.chat._id !== res.roomId) {
        return;
      }

      if (res?.messages) {
        this.markMessagesReadByRecipient(res.messages);
      } else {
        this.markAllMessagesReadByRecipient();
      }
    });

    this.debounceHandler = debounce(this.onScroll, 300);
  }

  destroyed() {
    this.sockets.unsubscribe('message');
  }

  onViewImages(media: MessageMediaInterface): void {
    this.viewerItems = [
      {
        src: media.link.large,
        thumb: media.link.small || undefined,
      },
    ];
    this.viewerIndex = 0;
  }

  @Watch('chat')
  onContactChange() {
    this.messages = {data: [], total: 0};
    this.params.page = 1;
    this.initLoad();
  }

  initLoad() {
    this.getMessages().then(() => {
      this.$nextTick(() => {
        this.scrollToUnreadMess();
        this.readAllMessages();
      });
    });
  }

  async sendMessage() {
    if (this.message || this.media.length) {
      const messageText = this.message;
      const media = this.media;
      this.message = '';
      this.media = [];
      this.imageThumbnails = [];
      const messageMediaIds: string[] = [];
      const tempId = moment().format();
      const tempMessage: MessageInterface = {
        _id: tempId,
        userId: vxm.user.data._id,
        roomId: this.chat._id,
        message: messageText,
        createdAt: tempId,
        updatedAt: tempId,
        read: [],
        medias: [],
        isUploading: !!media.length,
        filesUploadProgress: 0,
      };
      this.messages.total++;
      this.messages.data.unshift(tempMessage);

      if (media.length) {
        const type = media[0].type.split('/')[0] as ApiMediaTypes;
        const res: AxiosResponse<{data: {_id: string}[]}> = await vxm.post.addMedia({
          files: media,
          contentType: type,
          onUploadProgressEvent: (progressEvent: ProgressEvent) => {
            tempMessage.filesUploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          },
        });
        res.data.data.forEach((item) => {
          messageMediaIds.push(item._id);
        });
      }

      const data = {
        id: this.chat._id,
        data: {
          message: messageText,
          medias: messageMediaIds,
        },
      };

      vxm.user.createMessage(data).then((res: AxiosResponse<MessageInterface>) => {
        this.updateTempMessage(tempId, res.data);
        this.messagesContainer.scrollIntoView();
      });
    }
  }

  updateTempMessage(tempId: string, message: MessageInterface) {
    this.$emit('newMessage', message);
    for (let i = 0; i < this.messages.data.length; i++) {
      if (tempId === this.messages.data[i]._id) {
        this.$set(this.messages.data, i, message);
        break;
      }
    }
  }

  async addMedia(e: Event) {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const files: FileList = target.files as FileList;

    if (!(await this.checkMedia(files))) {
      target.value = '';
      return;
    }

    for (let i = 0; i < files.length; i += 1) {
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = (e) => {
        if (e.target) {
          const thumbnail = e.target.result as string;
          this.imageThumbnails.push(thumbnail);
        }
      };
    }

    this.media.push(...files);
    target.value = '';
  }

  removeMedia(index: number) {
    this.imageThumbnails.splice(index, 1);
    this.media.splice(index, 1);
  }

  getMessages() {
    return new Promise((resolve) => {
      vxm.user
        .getMessages({id: this.chat._id, params: this.params})
        .then((res: AxiosResponse<{data: MessageInterface[]; total: number}>) => {
          this.messages.data.push(...res.data.data);
          this.messages.total = res.data.total;
          resolve();
        });
    });
  }

  getOldMessages() {
    if (this.messages.total > this.messages.data.length) {
      this.params.lastMessageId = this.messages.data[this.messages.data.length - 1]._id;
      this.getMessages();
    }
  }

  deleteMessage(message: MessageInterface, index: number) {
    vxm.user.deleteMessage({roomId: this.chat._id, messageId: message._id}).then(() => {
      this.messages.data.splice(this.messages.data.length - 1 - index, 1);
    });
  }

  scrollToUnreadMess() {
    const messages = this.messages.data;
    if (messages.length) {
      return;
    }
    const messageElements = document.getElementsByClassName('message-block');
    let gotUnreadMessages = false;
    let firstUnreadMessageIndex = -1;
    for (let i = messages.length - 1; i >= 0; i -= 1) {
      if (!messages[i].read.includes(vxm.user.data._id)) {
        const firstUnreadMessage = messages[i];
        firstUnreadMessage.firstUnread = true;
        firstUnreadMessageIndex = i;
        messages.splice(i, 1, firstUnreadMessage);
        gotUnreadMessages = true;
        break;
      }
    }
    if (!gotUnreadMessages || firstUnreadMessageIndex === 0) {
      this.messagesContainer.scrollIntoView();
    } else {
      messageElements[firstUnreadMessageIndex - 1].scrollIntoView(false);
      this.messagesContainer.scrollTop -= messageElements[firstUnreadMessageIndex - 1].clientHeight;
    }
  }

  readAllMessages() {
    this.$socket.emit('message:read', {
      roomId: this.chat._id,
    });

    this.chat.unread = 0;

    if (this.chat.message) {
      this.chat.message.read.push(vxm.user.data._id);
    }
  }

  readMessage(message: MessageInterface) {
    this.$socket.emit('message:read', {
      roomId: this.chat._id,
      messages: [message._id],
    });
  }

  markMessagesReadByRecipient(messages: string[]) {
    let readMessages = messages.slice();
    for (const message of this.messages.data) {
      if (readMessages.includes(message._id)) {
        message.read.push(this.chat.users[0]._id);

        readMessages = readMessages.filter((value) => {
          return value !== message._id;
        });

        if (!readMessages.length) {
          break;
        }
      }
    }
  }

  markAllMessagesReadByRecipient() {
    for (const message of this.messages.data) {
      if (!message.read.includes(this.chat.users[0]._id)) {
        message.read.push(this.chat.users[0]._id);
      }
    }
  }

  isNewChatDate(message: MessageInterface, index: number): boolean {
    if (index === this.messages.data.length - 1) {
      return true;
    }
    if (message.firstUnread) {
      return true;
    }
    return moment(this.messages.data[index + 1].createdAt).diff(moment(message.createdAt), 'days') < 0;
  }

  isOutputMessage(message: MessageInterface): boolean {
    return vxm.user.data._id === message.userId;
  }

  showMenu(message: MessageInterface) {
    this.isMenuShow = true;
    this.menuId = message._id;
  }

  closeMenu() {
    this.isMenuShow = false;
  }

  async checkMedia(files: FileList) {
    if (!files.length) {
      return false;
    }

    if (this.media.length + files.length > imageQuantityUploadLimit) {
      this.$toasted.show(this.mediaErrorList.ImageQuantityError, {
        className: 'toasted-error',
      });
      return false;
    }

    for (let i = 0; i < files.length; i += 1) {
      if (
        files[i].type.split('/')[0] === 'image' &&
        !imageTypes.includes(files[i].type) &&
        !heicValidationRegex.test(files[i].name)
      ) {
        this.$toasted.show(this.mediaErrorList.ImageFormatError, {
          className: 'toasted-error',
        });
        return false;
      }

      if (files[i].type.split('/')[0] === 'image' && files[i].size > imageSizeLimitInBytes) {
        this.$toasted.show(this.mediaErrorList.ImageSizeError, {
          className: 'toasted-error',
        });
        return false;
      }

      if (files[i].type.split('/')[0] === 'video' && files[i].size > videoSizeLimitBytes) {
        this.$toasted.show(this.mediaErrorList.VideoSizeError, {
          className: 'toasted-error',
        });
        return false;
      }

      if (files[i].type.split('/')[0] === 'video') {
        const duration = await this.getVideoDuration(files[i]);
        if (parseInt(`${duration}`, 10) > videoDurationLimitSeconds) {
          this.$toasted.show(this.mediaErrorList.VideoDurationError, {
            className: 'toasted-error',
          });
          return false;
        }
      }
    }

    return true;
  }

  async getVideoDuration(file: File) {
    return await new Promise((resolve, reject) => {
      try {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = () => {
          resolve(video.duration);
        };
        video.onerror = () => {
          reject();
        };
        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });
  }

  selectEmoji(emoji: {data: string}) {
    this.message = this.message + emoji.data;
  }

  checkImageThumb(thumb: string) {
    return /^data:image\/(?!heic|heif)/i.test(thumb);
  }

  onScroll(): void {
    if (this.messages.total <= this.messages.data.length) {
      return;
    }
    if (this.isPageBottom()) {
      this.getOldMessages();
    }
  }

  isPageBottom(): boolean {
    const bottomLoaderHeight = this.getBottomLoaderHeight();
    return bottomLoaderHeight === null
      ? false
      : this.messagesContainer.scrollHeight +
          (this.messagesContainer.scrollTop - this.messagesContainer.clientHeight) <=
          bottomLoaderHeight;
  }

  getBottomLoaderHeight(): number | null {
    const bottomLoader = document.getElementById('bottom-loader');
    return bottomLoader ? bottomLoader.clientHeight : null;
  }
}
